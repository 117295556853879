import { createStore } from "solid-js/store";
import { createExperienceInfoInputs } from "../form";
import { FormPageProps } from "../model";
import { splitProps } from "solid-js";
import { Cmp, Mod } from ":mods";
import { updateShowExhibition } from "../apis/show-exhibition";

export function ExperienceInfo(props: FormPageProps) {
  const [local, others] = splitProps(props, ["stage_id", "data"]);
  const FormInputs = createExperienceInfoInputs();
  console.log("ExperienceInfo :: ", local.data);
  const [experienceData, setExperienceData] = createStore([
    {
      key: "box-04",
    },
    {
      job_position: null,
    },
    {
      work_in_museum: false,
    },
    {
      museum_name: null,
    },
    {
      department_name: null,
    },
    {
      work_in_museum_period: null,
    },
  ]);

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const values = FormInputs.Actions.getValuesSnakecase();
    setExperienceData(1, "job_position", values.job_title);
    if (values.currently_worked.toLowerCase() === "yes") {
      setExperienceData(2, "work_in_museum", true);
    } else {
      setExperienceData(2, "work_in_museum", false);
    }
    setExperienceData(3, "museum_name", values.museum_work);
    setExperienceData(4, "department_name", values.department_work);
    if (!!values.years_worked) {
      values.years_worked = local?.data[4]?.work_in_museum_period?.work_in_museum_period?.find(
        (r) => r.name === values.years_worked
      )?.id;
    }

    setExperienceData(5, "work_in_museum_period", values.years_worked);
    console.log("years_worked", values.years_worked);

    console.log("experienceData", experienceData);
    const formData = new FormData();
    formData.append("data", JSON.stringify(experienceData));
    console.log("JSON.stringify(experienceData)", JSON.stringify(experienceData));
    await updateShowExhibition(local.stage_id, formData).then((message) => {
      console.log(message, " :message");
      Cmp.Alert.actions.pushToast({
        type: "success",
        message: "Experience Info Updated successfully",
      });
    });
  };

  return (
    <main class="flex flex-col items-center w-full justify-between">
      <section class="flex flex-col gap-40px w-full pb-80px pt-40px">
        <form class="flex flex-col gap-20px">
          {local.data?.map((item, i) => (
            <div>
              {!item.job_position ||
                (i === 0 && (
                  <div class="flex flex-col gap-10px">
                    <FormInputs.JobTitle.Label title="Please tell us what is your job title">
                      <FormInputs.JobTitle.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                    </FormInputs.JobTitle.Label>
                    <FormInputs.JobTitle.Input
                      type="text"
                      class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2 h-50px"
                      placeholder=""
                    />
                  </div>
                ))}
              {!item.work_in_museum ||
                (i === 1 && (
                  <div class="flex flex-col gap-10px">
                    <FormInputs.CurrentlyWorked.Label title="Do you currently work or volunteer in a Museum?">
                      <FormInputs.CurrentlyWorked.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                    </FormInputs.CurrentlyWorked.Label>
                    <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
                      <FormInputs.CurrentlyWorked.Input
                        type="radio"
                        class=""
                        placeholder=""
                        name="CurrentlyWorked"
                        id="yes"
                      />
                      <p class="text-16px">Yes</p>
                    </div>
                    <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
                      <FormInputs.CurrentlyWorked.Input
                        type="radio"
                        class=""
                        placeholder=""
                        name="CurrentlyWorked"
                        id="No"
                      />
                      <p class="text-16px">No</p>
                    </div>
                  </div>
                ))}
              {!item.museum_name ||
                (i === 2 && (
                  <div class="flex flex-col gap-10px">
                    <FormInputs.MuseumWork.Label title="Which museum do you work or volunteer in?">
                      <FormInputs.MuseumWork.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                    </FormInputs.MuseumWork.Label>
                    <FormInputs.MuseumWork.Input
                      type="text"
                      class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2  h-50px"
                      placeholder=""
                    />
                  </div>
                ))}
              {!item.department_name ||
                (i === 3 && (
                  <div class="flex flex-col gap-10px">
                    <FormInputs.DepartmentWork.Label title="Which Department do you work or Volunteer in?">
                      <FormInputs.DepartmentWork.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                    </FormInputs.DepartmentWork.Label>
                    <FormInputs.DepartmentWork.Input
                      type="text"
                      class="bg-inherit border-2 border#p border-solid text-0.8rem px-2.5 py-2 mb-2  h-50px"
                      placeholder=""
                    />
                  </div>
                ))}
              {!!item.work_in_museum_period && (
                <div class="flex flex-col gap-10px">
                  <FormInputs.YearsWorked.Label title="How many years have you worked in a museum or within the museum sector">
                    <FormInputs.YearsWorked.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                  </FormInputs.YearsWorked.Label>
                  {item.work_in_museum_period?.work_in_museum_period?.map((p) => {
                    const selected = item.work_in_museum_period.selected_option === p.id;
                    return (
                      <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
                        <FormInputs.YearsWorked.Input
                          type="radio"
                          class=""
                          placeholder=""
                          name="work_in_museum_period"
                          checked={selected}
                          // value={selected ? "yes" : "no"}
                          id={p.name}
                        />
                        <p class="text-16px">{p.name}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}

          <div class="flex justify-end">
            <FormInputs.Actions.Button
              class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-175px h-44px text#n-50  h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
              onclick={onSubmit}
              statusValid={"Save and Close"}
              statusInvalid={"Save and Close"}
            />
          </div>
        </form>
      </section>
    </main>
  );
}
